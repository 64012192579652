import React from "react";
import { ReactElement } from "react";
import * as DashboardStore from '../../store/DashboardStore';
import { connect } from 'react-redux';
import VehiclesTab from "./VehiclesTab";
import DashboardTab from "./DashboardTab";
import MapPopup from "../common/MapPopup";
import * as DashboardIcons from "./DashboardIcons";
import { VehicleChipMessages, VehicleChipStyles } from "../common/DashboardVehicle";
import DanlawVehicle from "../../models/DanlawVehicle";

interface CustomerDashboardProps {
    
}

interface CustomerDashboardState {
    selectedVehicle: DanlawVehicle;
    singleVehicleMap: any;
}

export function generateMapIconsWithId(vehicles: DanlawVehicle[], idValue: string): ReactElement[] {
    let icons: ReactElement[] = [];
    let index = 1;
    if (vehicles) {
        vehicles.forEach((vehicle: DanlawVehicle) => {
            icons.push(DashboardIcons.RelevantIcon(idValue + index, vehicle.chipMessage || ""));
            index++;
        });
    }
    return icons;
}

export function createMap(container: string, style?: string, center?: any, zoom?: number, dragPan = true, showZoomControls = true, interactive = true ): any {
    const Radar = window.Radar;
    return Radar.ui.map({
        container: container,
        style: style || 'radar-default-v1',
        center: center,
        zoom: zoom,
        dragPan: dragPan,
        showZoomControls: showZoomControls,
        interactive: interactive
    });
}

export function createMapMarker(container: any, longAndLat: any, markerOptions?: any ) {
    const Radar = window.Radar;
    return Radar.ui.marker(markerOptions)
        .setLngLat(longAndLat)
        .addTo(container);
}

export function zoomOnVehicle(vehicle: DanlawVehicle, vehiclesMap: any) {
    let selectedMarker: any = undefined;
    let markers = vehiclesMap.getMarkers();
    let zoomButton = document.getElementById("zoom-button" + vehicle.id) as HTMLElement;

    markers.forEach((marker: any) => {
        if (marker._element.id === ("svgIcon-" + vehicle.id)) {
            selectedMarker = marker;
        }
    });
    
    if (vehiclesMap.getZoom() >= 17) {
        vehiclesMap.fitToMarkers({speed: 2, duration: 2500});
        zoomButton.textContent = "Zoom In";
    }
    else {
        vehiclesMap.flyTo({
            center: [selectedMarker._lngLat.lng, selectedMarker._lngLat.lat],
            zoom: 19,
            speed: 1,
            duration: 2500
        });
        zoomButton.textContent = "Zoom Out";
    }
}

export class CustomerDashboard extends React.Component<CustomerDashboardProps, CustomerDashboardState> {
    constructor(props: CustomerDashboardProps) {
        super(props);
        this.state = {
            selectedVehicle: this.generateDanlawVehicles()[0],
            singleVehicleMap: undefined
        };
    }

    componentDidMount(): void {
        /// Set intial Dashboard button style
        let dashboardButton = document.getElementById("header-dashboard") as HTMLElement || HTMLElement;
        dashboardButton.style.fontWeight = "700";
        dashboardButton.style.background = "var(--Light-Mode-Light-Blue, #F0F4FB) !important";
    }

    generateDanlawVehicles(): DanlawVehicle[] {
        let vehicles: DanlawVehicle[] = [];
        vehicles.push({id: "1", vehicleType: "2019 Toyota Camry", chipStatus: VehicleChipStyles.Success, chipMessage: VehicleChipMessages.Moving, nearestAddress: { addressLine1: "6789 Main St", city: "New York", state: "NY", zipCode: "10001" }, latLong: { latitude: 40.7342465, longitude: -73.9910078}, vin: "6789FAKEVIN", deviceInfo: {deviceStatus: "Connected", deviceName: "Device1", deviceId: "OBD 67890"}});
        vehicles.push({id: "2", vehicleType: "2020 Toyota Camry", chipStatus: VehicleChipStyles.Warning, chipMessage: VehicleChipMessages.Idling, nearestAddress: { addressLine1: "5678 Main St", city: "New York", state: "NY", zipCode: "10001" }, latLong: { latitude: 40.7442465, longitude: -73.9810078}, vin: "5678FAKEVIN", deviceInfo: {deviceStatus: "Connected", deviceName: "Device2", deviceId: "OBD 56789"}});
        vehicles.push({id: "3", vehicleType: "2021 Toyota Camry", chipStatus: VehicleChipStyles.Alert, chipMessage: VehicleChipMessages.BeingTowed, nearestAddress: { addressLine1: "4567 Main St", city: "New York", state: "NY", zipCode: "10001" }, latLong: { latitude: 40.7542465, longitude: -73.9710078}, vin: "4567FAKEVIN", deviceInfo: {deviceStatus: "Connected", deviceName: "Device3", deviceId: "OBD 45678"}});
        vehicles.push({id: "4", vehicleType: "2022 Toyota Camry", chipStatus: VehicleChipStyles.Neutral, chipMessage: VehicleChipMessages.Parked, nearestAddress: { addressLine1: "3456 Main St", city: "New York", state: "NY", zipCode: "10001" }, latLong: { latitude: 40.7362465, longitude: -73.9610078}, vin: "3456FAKEVIN", deviceInfo: {deviceStatus: "Connected", deviceName: "Device4", deviceId: "OBD 34567"}});
        vehicles.push({id: "5", vehicleType: "2023 Toyota Camry", chipStatus: VehicleChipStyles.Warning, chipMessage: VehicleChipMessages.Idling, nearestAddress: { addressLine1: "2345 Main St", city: "New York", state: "NY", zipCode: "10001" }, latLong: { latitude: 40.7372465, longitude: -73.9510078}, vin: "2345FAKEVIN", deviceInfo: {deviceStatus: "Connected", deviceName: "Device5", deviceId: "OBD 23456"}});
        vehicles.push({id: "6", vehicleType: "2024 Toyota Camry", chipStatus: VehicleChipStyles.Success, chipMessage: VehicleChipMessages.Moving, nearestAddress: { addressLine1: "1234 Main St", city: "New York", state: "NY", zipCode: "10001" }, latLong: { latitude: 40.7382465, longitude: -73.9410078}, vin: "1234FAKEVIN", deviceInfo: {deviceStatus: "Connected", deviceName: "Device6", deviceId: "OBD 12345"}});
        return vehicles;
    }
    
    render(): ReactElement {
        const { selectedVehicle } = this.state;
        let vehiclesTabProps = {
            vehicles: this.generateDanlawVehicles(),
            selectedVehicle: selectedVehicle
        }
        return (
            <>
            <DashboardTab vehicles={this.generateDanlawVehicles()} />
            <VehiclesTab {...vehiclesTabProps} />
            <div className="container devices-container" id="devices-container" style={{display:"none"}}>
                <div className="row">
                    <div className="col-md-12">
                        <h1 className="text-center">Devices Dashboard</h1>
                    </div>
                </div>
            </div>
            </>
        )
    }
}

const mapStateToProps = (state: any, ownProps: any) => ({
    error: state.drivers.error,
    selectedVehicle: state.drivers.policy,
    isLoading: state.drivers.isLoading,
    loggedInUser: state.loggedInUser
});

export default connect(
    mapStateToProps,
    DashboardStore.actionCreators,
)(CustomerDashboard as any);