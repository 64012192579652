export class MapRecenterButton{
    map: any;
    container: any;

    onClick(){
        this.map.fitToMarkers({speed: 2, duration: 2500});
    }

    onAdd(map: any){
        this.map = map;
        this.container = document.createElement('button');
        this.container.className = 'recenter-button';
        this.container.textContent = 'Recenter';
        this.container.addEventListener('contextmenu', (e: any) => e.preventDefault());
        this.container.addEventListener('click', () => this.onClick());
        return this.container;
    }

    onRemove(){
        this.container.parentNode.removeChild(this.container);
        this.map = undefined;
    }
}