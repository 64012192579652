import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import {
    MSAL_LOGIN,
    MSAL_LOGOUT,
    MSAL_ERROR,
    MSAL_LOGIN_FAILURE,
    ActionWithPayload,
    MSAL_TOKEN_ERROR,
    MSAL_LOGIN_SUCCESS,
    KnownAction,
    MSAL_ACQUIRE_TOKEN,
    MSAL_LOGIN_REDIRECT,    
    MSAL_LOGIN_SILENT
} from './Actions';
import { LoggedInUser, UserRole } from '../models/LoggedInUser';
import { AuthProvider } from '../api/authApi'

export interface loggedInUserState {
    loggedInUser: LoggedInUser;
}

export const msalAuthActions = {

    silentloginUser: (): AppThunkAction<ActionWithPayload> => (dispatch: any) => {
        dispatch({
            type: MSAL_LOGIN_SILENT,
            payload: null
        });

        let userAccount = AuthProvider.getAccount();
        if (userAccount) {
            //login success
            dispatch({
                type: MSAL_LOGIN_SUCCESS,
                payload: userAccount,
            });
            dispatch({
                type: MSAL_ACQUIRE_TOKEN,
                payload: AuthProvider.getToken()
            });
        }
        else {
            AuthProvider.login();
            dispatch({
                type: MSAL_LOGIN_FAILURE,
                payload: null
            });
            //Silent Login Failure
        }
    },

    loginUser: (): AppThunkAction<ActionWithPayload> => (dispatch: any) => {

        let userAccount = AuthProvider.getAccount();

        if (!userAccount) {
            AuthProvider.login();
        }
        else {
            dispatch({
                type: MSAL_LOGIN_SUCCESS,
                payload: userAccount,
            });
        }
    },

    logoutUser: (): AppThunkAction<ActionWithPayload> => (dispatch: any) => {
        AuthProvider.logout();       
    },

    loginError: (): AppThunkAction<ActionWithPayload> => (dispatch: any) => {
        dispatch({
            type: MSAL_LOGIN_FAILURE,
            payload: "User Not Authorized",
        }); 
    },

    
};

export const reducer:
    Reducer<any> = (
        state: any | undefined,
        incomingAction: Action,
    ): any => {
        if (state === undefined) {
            return new LoggedInUser(false);
        }

        const action = incomingAction as KnownAction;
        switch (action.type) {
            case MSAL_LOGIN:
                return {
                    ...state,
                    isAuthenticated: false,
                    account: null,
                    hasError: false,
                    errorMessage: null
                };
            case MSAL_LOGIN_SUCCESS:
                return {
                    ...state,
                    isAuthenticated: true,
                    account: action.payload,
                    userRole: LoggedInUser.GetUserRole(action.payload),
                    hasError: false,
                    errorMessage: null
                };
            case MSAL_ACQUIRE_TOKEN:
                return {
                    ...state,
                    idToken: action.payload
                };
            case MSAL_TOKEN_ERROR:
            case MSAL_LOGIN_FAILURE:
                return {
                    ...state,
                    isAuthenticated: false,
                    account: null,
                    hasError: true,
                    errorMessage: action.payload
                };
            case MSAL_LOGOUT:
                return {
                    ...state,
                    isAuthenticated: false,
                    account: null,
                    hasError: false,
                    errorMessage: ""
                }
            case MSAL_ERROR:
                return {
                    ...state,
                    isAuthenticated: false,
                    account: null,
                    hasError: true,
                    errorMessage: "MSAL ERROR"
                }
            default:
                return state;
        }
    };