import React, { ChangeEvent } from 'react';
import Button, { ButtonStyles } from '../common/Button';
import { Driver } from './../../models/Driver';
import { StateDropdown } from '../common/StateDropdown';
import Input from '../common/Input';
import ButtonSwitch from '../common/ButtonSwitch';
import DatePicker from '../common/DatePicker';
import { excludeSpecialCharacters, formatEffectiveDate, formatPhoneNumber } from '../../helpers/textHelpers';

interface DriverModalProps {
    driver: Driver;
    onClose(): void;
    onSave(driver: Driver): void;
    mode: PopUpMode;
}

export enum PopUpMode {
    Add,
    AddManually,
    Update
}

export interface Style {
    display: string;
}

export default class DriverModalPopup extends React.Component<DriverModalProps, any> {
    dateRegex = RegExp(/(0[1-9]|1[012])[\/](0[1-9]|[12][0-9]|3[01])[\/](20)\d\d/);

    constructor(props: DriverModalProps) {
        super(props);
        this.state = { 
            enrolled: false,
            currentDriver: this.props.driver,
            suppressCommunication: false,
            wasDriversLicenseNumberFocusedOn: false,
            validationErrors: {} as any,
            telematicsVueRequest: true
        };
    }

    componentDidMount() {
        const comp = document.getElementById('id');
        if (comp) {
            comp.focus();
        }
    }

    toggleSuppressCommunication = (): void => {
        this.setState({
            suppressCommunication: !this.state.currentDriver.suppressCommunication,
            currentDriver: {
                ...this.state.currentDriver,
                suppressCommunication: !this.state.currentDriver.suppressCommunication,
            },
        });
    }

    toggleTelematicsEnrollment = () => {
        this.setState({ 
            enrolled: !this.state.currentDriver.isEnrolled,
            currentDriver: {
                ...this.state.currentDriver,
                isEnrolled: !this.state.currentDriver.isEnrolled
            }
        });
    };

    onCancel = () => {
        const { onClose } = this.props;
        onClose();
    }

    onChange = (e: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            currentDriver: {
                ...this.state.currentDriver,
                [e.target.id]: excludeSpecialCharacters(e.target.value)
            }
        })
    }

    updateMobileNumber = (e: ChangeEvent<HTMLInputElement>) => {
        const { currentDriver } = this.state;

        this.setState({
            currentDriver: {
                ...this.state.currentDriver,
                mobileNumber: formatPhoneNumber(e.target.value, currentDriver.mobileNumber)
            }
        })
    }

    updateEffectiveDate = (e: ChangeEvent<HTMLInputElement>) => {
        const { currentDriver } = this.state;

        this.setState({
            currentDriver: {
                ...this.state.currentDriver,
                enrollmentEffectiveDate: formatEffectiveDate(e.target.value, currentDriver.enrollmentEffectiveDate)
            }
        })
    }

    onDriversLicenseFocus = () => {
        this.setState({
            currentDriver: {
                ...this.state.currentDriver,
                driversLicenseNumber: '',
            },
            wasDriversLicenseNumberFocusedOn: true,
        });
    }

    maskDriversLicenseNumber = (wasDriversLicenseNumberFocusedOn: boolean,
        isDriversLicenseNumberDirty: boolean,
        driversLicenseNumber: string) => {
        const mask = '****';

        if (driversLicenseNumber == null) {
            return driversLicenseNumber;
        }

        if (!wasDriversLicenseNumberFocusedOn
            && !isDriversLicenseNumberDirty) {
            return mask.concat(driversLicenseNumber.substr(driversLicenseNumber.length - 4));
        }

        return driversLicenseNumber;
    }

    onSave = () => {
        const { onSave, driver, mode } = this.props;
        const { currentDriver } = this.state;
        let validationErrors = {} as any;
        currentDriver.telematicsVueRequest = true;

        if (mode === PopUpMode.AddManually)
        {
            currentDriver.driverStatus = "Undefined";
        }

        if (mode === PopUpMode.Add && !this.validateMobileNumber(currentDriver, validationErrors, true))
        {
            this.setState({
                validationErrors
            });
           return;
        }
        else if (!this.validateDriver()){
            return;
        }

        if ((mode === PopUpMode.Update
            && currentDriver.driversLicenseNumber !== driver.driversLicenseNumber)
            || mode === PopUpMode.AddManually) {
            currentDriver.isDriversLicenseNumberDirty = true;
        }

        onSave(currentDriver);
    }

    validateDriver() {
        const { currentDriver } = this.state;
        let validationErrors = {} as any;
        let isValid = true;
        if (currentDriver.id === "" || currentDriver.id == null) {
            validationErrors.id = "Driver ID is required.";
            isValid = false;
        }
        if (currentDriver.firstName == null || currentDriver.firstName.replace(/ +/g, '').length < 2) {
            validationErrors.firstName = "First name is required.";
            isValid = false;
        }
        if (currentDriver.lastName == null || currentDriver.lastName.replace(/ +/g, '').length < 2){
            validationErrors.lastName = "Last name is required."
            isValid = false;
        }
        if(currentDriver.driversLicenseNumber === "" || currentDriver.driversLicenseNumber == null){
            validationErrors.driversLicenseNumber = "Drivers license number is required."
            isValid = false;
        }
        else if(currentDriver.driversLicenseNumber && currentDriver.driversLicenseNumber.length < 4 || currentDriver.driversLicenseNumber == null){
            validationErrors.driversLicenseNumber = "Drivers license appears to be incorrect."
            isValid = false;
        }

        isValid = this.validateMobileNumber(currentDriver, validationErrors, isValid);

        if(currentDriver.driversLicenseState === "" ||
           currentDriver.driversLicenseState == null ||
           currentDriver.driversLicenseState == "State"){
            validationErrors.driversLicenseState = "Drivers license state is required."
            isValid = false;
        }
        if (currentDriver.enrollmentEffectiveDate == "" ||
            currentDriver.enrollmentEffectiveDate == null ||
            !this.dateRegex.test(currentDriver.enrollmentEffectiveDate)) {
            validationErrors.enrollmentEffectiveDate = "Enrollment effective date is required."
            isValid = false;
        }

        this.setState({
            validationErrors
        });
        return isValid;
    }

    private validateMobileNumber(currentDriver: any, validationErrors: any, isValid: boolean) {
        let mobileNo = currentDriver.mobileNumber?.length == 15 ? currentDriver.mobileNumber?.substring(1) : currentDriver.mobileNumber;
        if (mobileNo != null && mobileNo != "" && mobileNo.length != 14) {
            validationErrors.mobileNumber = "Mobile number is required.";
            isValid = false;
        }
        return isValid;
    }

    render() {
        const { mode } = this.props;
        const { currentDriver, wasDriversLicenseNumberFocusedOn, validationErrors } = this.state;
        if (mode === PopUpMode.AddManually) {
            currentDriver.isEnrolled = true;
        }
        return (
            <div
                id="driverPopup"
                className="modal modal--show"
                style={{ display: 'block' }}
            >
                <div className="modal-container">
                    <span className="tab-filler" />
                    <div className="section-header ">
                        <span className="geico-icon icon-profile" />
                        <h2>
                            {mode === PopUpMode.AddManually ? 'Add Driver Manually' : this.AddOrUpdateDriverTitle(mode)}
                        </h2>
                    </div>
                    <button
                        className="btn-close icon-close"
                        aria-label="Close"
                        type="button"
                        onClick={this.onCancel}
                    />
                    <div className="modal-content ">
                        <div className="container margin-top">
                            <div className="row" id="row-0">
                                <div className="col-sm-12 col-md-6">
                                    <Input
                                        id="id"
                                        label="Driver ID"
                                        onChange={this.onChange}
                                        value={currentDriver.id}
                                        name="id"
                                        size={29}
                                        disabled={mode === PopUpMode.Add? true : false}
                                        errorMessage={validationErrors.id}
                                    />
                                </div>
                            </div>
                            <div className="row" id="row-1">
                                <div className="col-sm-12 col-md-6">
                                    <Input
                                        id="firstName"
                                        label="First Name"
                                        onChange={this.onChange}
                                        value={currentDriver.firstName}
                                        name="firstName"
                                        size={29}
                                        disabled={mode === PopUpMode.Add ? true : false}
                                        errorMessage={validationErrors.firstName}
                                    />
                                </div>
                                <div className="col-sm-12 col-md-6">
                                    <Input
                                        id="lastName"
                                        label="Last Name"
                                        onChange={this.onChange}
                                        value={currentDriver.lastName}
                                        name="lastName"
                                        size={29}
                                        disabled={mode === PopUpMode.Add ? true : false}
                                        errorMessage={validationErrors.lastName}
                                    />
                                </div>
                            </div>
                            <div className="row" id="row-2">
                                <div className="col-sm-12 col-md-6">
                                    <Input
                                        id="driversLicenseNumber"
                                        label="License Number"
                                        onChange={this.onChange}
                                        onFocus={this.onDriversLicenseFocus}
                                        value={mode === PopUpMode.Update
                                            ? this.maskDriversLicenseNumber(
                                            wasDriversLicenseNumberFocusedOn,
                                            currentDriver.isDriversLicenseNumberDirty,
                                            currentDriver.driversLicenseNumber)
                                            : currentDriver.driversLicenseNumber}
                                        name="LicenseNumTextBox"
                                        size={29}
                                        disabled={mode === PopUpMode.Add ? true : false}
                                        errorMessage={validationErrors.driversLicenseNumber}
                                    />
                                </div>
                                <div className="col-sm-12 col-md-6">
                                    <StateDropdown
                                        id="driversLicenseState"
                                        value={currentDriver.driversLicenseState}
                                        onChange={this.onChange}
                                        disabled={mode === PopUpMode.Add ? true : false}
                                        errorMessage={validationErrors.driversLicenseState}
                                    />
                                </div>
                            </div>
                            <div className="row" id="row-3">
                                <div className="col-sm-12 col-md-6">
                                    <Input
                                        id="mobileNumber"
                                        label={this.GetMobileNoSource(currentDriver)}
                                        value={currentDriver.mobileNumber?.length == 15 ? currentDriver.mobileNumber?.substring(1) : currentDriver.mobileNumber}
                                        name="mobileNumber"
                                        size={29}
                                        maxLength={14}
                                        errorMessage={validationErrors.mobileNumber}
                                        onChange={this.updateMobileNumber}
                                    />
                                </div>
                                <div className="col-sm-12 col-md-6">
                                    <ButtonSwitch
                                        id="enrolled"
                                        isChecked={currentDriver.isEnrolled}
                                        onClick={this.toggleTelematicsEnrollment}
                                        title="Enrolled in Telematics?"
                                    />
                                </div>
                            </div>
                            <div className="row" id="row-4">
                                <div className="col-sm-12 col-md-6">
                                    <DatePicker
                                        id="enrollmentEffectiveDate"
                                        name="effectiveDate"
                                        size={29}
                                        maxLength={10}
                                        disabled={mode === PopUpMode.Add ? true : false}
                                        errorMessage={validationErrors.enrollmentEffectiveDate}
                                        value={currentDriver.enrollmentEffectiveDate}
                                        label="Effective Date"
                                        onChange={this.updateEffectiveDate}
                                     />
                                </div>
                                <div className="col-sm-12 col-md-6">
                                    <ButtonSwitch id="suppressCommunication"
                                        isChecked={this.state.currentDriver.suppressCommunication}
                                        onClick={this.toggleSuppressCommunication}
                                        title="Suppress Communications"
                                    />
                                </div>
                            </div>
                            <div className="row" id="row-3">
                                <div className="col-sm-6" />
                                <div className="col-sm-6">
                                    <div className="modal--call-to-action-bar">
                                        <Button
                                            btnStyle={ButtonStyles.BlueTableButton}
                                            onClick={this.onCancel}
                                            title="Cancel"
                                            key="Cancel"
                                        />
                                        <Button
                                            btnStyle={ButtonStyles.GreenTableButton}
                                            onClick={this.onSave}
                                            title="Save"
                                            key="Save"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private GetMobileNoSource(currentDriver: any): string | undefined {
        let mobileNoSource = "Mobile";
        if (currentDriver.mobileNumber?.charAt(0) == "C") {
            mobileNoSource = "Mobile (CPROA-missing in CMT)"
        }
        else if (currentDriver.mobileNumber?.charAt(0) == "T") {
            mobileNoSource = "Mobile (CMT)"
        }

        return mobileNoSource;
    }

    private AddOrUpdateDriverTitle(mode: PopUpMode): React.ReactNode {        
        return mode === PopUpMode.Add ? 'Add Driver' : 'Update Driver';
    }
}
