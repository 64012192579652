import React, { ChangeEvent } from "react";
import { ReactElement } from "react";
import * as DashboardStore from '../../store/DashboardStore';
import { connect } from 'react-redux';
import DashboardVehicle, { VehicleChipMessages, VehicleChipStyles } from "../common/DashboardVehicle";
import { createMap, createMapMarker, generateMapIconsWithId } from "./CustomerDashboard";
import { MapRecenterButton } from "./MapRecenterButton";
import DanlawVehicle from "../../models/DanlawVehicle";
import PopupModel from "../../models/PopupModel";
import MapPopup from "../common/MapPopup";
import '@gdk/base/dist/styles/gdk-base.css';
import { AllVehiclesButton } from "./AllVehiclesButton";

interface VehiclesTabProps {
    vehicles: DanlawVehicle[];
    selectedVehicle: DanlawVehicle;
}

interface VehiclesTabState {
    vehicles: DanlawVehicle[];
    singleVehicleMap: any;
    vehiclesMap: any;
    selectedVehicle: DanlawVehicle;
    popups: any;
}

export class VehiclesTab extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            vehicles: this.props.vehicles,
            singleVehicleMap: undefined,
            vehiclesMap: undefined,
        };
    }

    componentDidMount(): void {
        const { vehicles } = this.props;

        this.Radar();

        const vehiclesMap = createMap('vehicles-map', '2111b9d1-66dd-4b4a-afac-30755940c153', undefined, 5);
        const singleVehicleMap = createMap('single-vehicle-map', '2111b9d1-66dd-4b4a-afac-30755940c153', undefined, 5);

        for (let i = 1; i <= vehicles.length; i++) {
            let popup = document.getElementById("popup" + i) as HTMLElement;
            let svgIcon = document.getElementById("svgIcon-" + i) as HTMLElement;
            createMapMarker(vehiclesMap, [vehicles[i-1].latLong?.longitude,vehicles[i-1].latLong?.latitude], { popup: {element: popup, offset:35}, element: svgIcon });
        }
        
        let markersList = vehiclesMap.getMarkers();
        vehiclesMap.fitToMarkers({duration: 0});
        vehiclesMap.center = this.findCenter(markersList);

        const recenterButton = new MapRecenterButton();
        vehiclesMap.addControl(recenterButton);

        this.setState({ vehiclesMap: vehiclesMap });

        let popup = document.getElementById("popupSingle") as HTMLElement;
        
        for (let i = 1; i <= vehicles.length; i++) {
            let svgIcon = document.getElementById("svgIconSingle-" + i) as HTMLElement;
            svgIcon.setAttribute("width", "13%");
            svgIcon.setAttribute("height", "13%");
            createMapMarker(singleVehicleMap, [vehicles[i-1].latLong?.longitude, vehicles[i-1].latLong?.latitude], { popup: {element: popup}, element: svgIcon });
        }

        singleVehicleMap.fitToMarkers({duration: 0});

        const viewAllVehiclesButton = new AllVehiclesButton();
        singleVehicleMap.addControl(viewAllVehiclesButton, 'top-left');

        this.setState({ singleVehicleMap: singleVehicleMap });

        document.getElementById("vehicles-list-container")?.addEventListener('click', () => this.deselectVehicle(true));
        document.getElementById("vehicles-map-container")?.addEventListener('click', () => this.deselectVehicle(false));
    }

    createMapPopup(popupModel: PopupModel) {
        const { vehicleInformationOnClick } = this.props;

        return(
            <MapPopup 
            chipMessage={popupModel.chipMessage || ""} 
            chipStatus={popupModel.chipStatus || ""} 
            id={popupModel.id} 
            vehicle={popupModel.vehicle} 
            singleVehicleMap={popupModel.singleVehicleMap} 
            vehiclesMap={popupModel.vehiclesMap} 
            vehicles={popupModel.vehicles} 
            vehicleInformationOnClick={vehicleInformationOnClick}/>
        )
    }

    Radar() {
        const script = document.createElement("script");
        script.src = "https://js.radar.com/v4.4.2/radar.min.js";
        script.async = true;
        document.body.appendChild(script);
    };

    findCenter(markersList: any): any {
        let lat = 0;
        let long = 0;
        markersList.forEach((marker: any) => {
            lat += marker.getLngLat().lat;
            long += marker.getLngLat().lng;
        });
        return [long/markersList.length, lat/markersList.length];
    }

    vehicleDropDownRow(vehicle: DanlawVehicle): ReactElement {
        return (
            <option id={vehicle.id}>{vehicle.vehicleType}</option>
        )
    }

    onChangeSelectedVehicle = (e: ChangeEvent<HTMLSelectElement>) => {
        const { vehicles, singleVehicleMap } = this.state;
        let newVehicle = vehicles.find((vehicle: DanlawVehicle) => vehicle.id === e.target.selectedOptions[0].id);
        this.props.vehicleInformationOnClick(newVehicle, singleVehicleMap, vehicles);
        let vehicleSelect = document.getElementById("vehicleSelect") as HTMLSelectElement;
        if (vehicleSelect !== null) {
            vehicleSelect.selectedIndex = 0;
        }
    }

    deselectVehicle = (closePopup: boolean) => {
        const { vehicles } = this.props;
        const { vehiclesMap } = this.state;

        vehicles.forEach((vehicleFromList: DanlawVehicle) => {
            let vehicle = document.getElementById("vehicle-info" + vehicleFromList.id) as HTMLElement;
            vehicle.style.removeProperty("background");
            vehicle.style.removeProperty("border");
        });

        let markers = vehiclesMap.getMarkers();
        markers.forEach((marker: any) => {
            marker._element.children[0].style.transform = "scale(1.0)";
            marker._element.children[1].style.transform = "scale(1.0)";
        });

        if (closePopup) {
            let closeButton = document.getElementsByClassName("maplibregl-popup-close-button")[0];
            closeButton?.dispatchEvent(new MouseEvent("click"));
        }
    }
    
    render(): ReactElement {
        const { selectedVehicle, generateMapPopups } = this.props;
        const { vehicles, singleVehicleMap, vehiclesMap, popups } = this.state;
        let vehicleChipClass = "vehicle-chip-label chips chips-" + selectedVehicle?.chipStatus;
        return (
            <>
            <div className="container vehicles-container" id="vehicles-container" style={{display:"none"}}>
                <div className="row">
                    <div className="col-md-4 vehicles-list-container" id="vehicles-list-container" >
                        <h3 className="vehicles-list-title">Vehicles</h3>
                        {vehicles.map((vehicle: DanlawVehicle) => <DashboardVehicle danlawVehicle={vehicle} vehiclesMap={vehiclesMap} vehicles={vehicles}></DashboardVehicle>)}
                    </div>
                    <div className="col-md-8 vehicle-map-column">
                        <div className="flexible-container vehicle-map-container">
                            <div id="vehicles-map-container" style={{ position: 'absolute' }}>
                                <div id="vehicles-map" style={{ position: 'absolute' }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                {(generateMapPopups(vehicles, selectedVehicle, singleVehicleMap, vehiclesMap).payload).map((popup: PopupModel) => this.createMapPopup(popup))}
                {generateMapIconsWithId(vehicles, "svgIcon-").map((icon: ReactElement) => icon)}
            </div>
            <div className="container single-vehicle-container" id="single-vehicle-container" style={{display:"none"}}>
                <div className="row">
                    <div className="col-md-12 single-vehicle-select-vehicle">
                        <div className="vehicle-select-banner">
                            <div className="vehicle-select-type">
                                {selectedVehicle?.vehicleType}
                            </div>
                            <div className="vehicle-select-dropdown">
                                <form>
                                    <div className="form-field">
                                        <div className="select-box">
                                            <select id="vehicleSelect" name="vehicleSelect" defaultValue="Select Vehicle" onChange={this.onChangeSelectedVehicle}>
                                                <option disabled hidden>Select Vehicle</option>
                                                {vehicles.map((vehicle: DanlawVehicle) => this.vehicleDropDownRow(vehicle))}
                                            </select>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-8 single-vehicle-map-column">
                        <div className="flexible-container single-vehicle-map-container">
                            <div id="single-vehicle-map-container" style={{ position: 'absolute' }}>
                                <div id="single-vehicle-map" style={{ position: 'absolute' }} />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 single-vehicle-details-list-container">
                        <div className="flexible-container single-vehicle-info">
                            <div className="single-vehicle-status">
                                <p className="text-left text-bold vehicle-info-header">Status <span className={vehicleChipClass}>{selectedVehicle?.chipMessage}</span></p>
                            </div>
                            <div className="single-vehicle-data">
                                <p className="text-left text-bold">Near</p>
                                <p className="text-left">{selectedVehicle?.nearestAddress.addressLine1}</p>
                                <p className="text-left">{selectedVehicle?.nearestAddress?.city}, {selectedVehicle?.nearestAddress?.state} {selectedVehicle?.nearestAddress?.zipCode}</p>
                            </div>
                                <span className="stroke-separator"></span>
                            <div className="single-vehicle-data">
                                <p className="text-left text-bold">Since</p>
                                <p className="text-left">7:30 AM, September 19th (1 hour ago)</p>
                            </div>
                        </div>
                        <div className="flexible-container single-vehicle-info">
                            <p className="text-left text-bold vehicle-info-header">Vehicle Info</p>
                            <div className="single-vehicle-data">
                                <p className="text-left text-bold">Model</p>
                                <p className="text-left">{selectedVehicle?.vehicleType}</p>
                            </div>
                                <span className="stroke-separator"></span>
                            <div className="single-vehicle-data">
                                <p className="text-left text-bold">VIN</p>
                                <p className="text-left">{selectedVehicle?.vin}</p>
                            </div>
                        </div>
                        <div className="flexible-container single-vehicle-info">
                            <p className="text-left text-bold vehicle-info-header">Device Info</p>
                            <div className="single-vehicle-data">
                                <p className="text-left">{selectedVehicle?.deviceInfo?.deviceId}</p>
                                <p className="tags tags-confirmation">{selectedVehicle?.deviceInfo?.deviceStatus}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    {generateMapIconsWithId(vehicles, "svgIconSingle-").map((icon: ReactElement) => icon)}
                </div>
            </div>
            </>
        )
    }
}

const mapStateToProps = (state: any) => ({
    selectedVehicle: state.dshs.selectedVehicle,
    popups: state.dshs.popups,
});

export default connect(mapStateToProps, DashboardStore.actionCreators)(VehiclesTab as any);