import * as MSAL from 'msal';
import AuthConfig from '../config/authConfig';

declare type LoginCallBackHandler = (IsLoggedIn: boolean) => void;

class AuthApi {

    private loginHandler: LoginCallBackHandler | undefined;

    private msalApp = new MSAL.UserAgentApplication({
        auth: AuthConfig.getConfig()
    });

    login() {
        if (this.msalApp) {
            console.log("Login Redirect hit at: " + new Date().toString());
            this.msalApp.loginRedirect(AuthConfig.loginRequest);
        }
    }
    silentlogin() {
        console.log("Token Redirect hit at: " + new Date().toString());
        this.msalApp.acquireTokenRedirect(AuthConfig.loginRequest);
    }

    logout() {
        if (this.msalApp) {
            this.msalApp.logout();
        }
    }

    getToken(): any {
        return sessionStorage.getItem("telematics.msal.idtoken")
    }

    getAccount(): any {
        if (this.msalApp) {
            return this.msalApp.getAccount();
        }
        return null;
    }

    registerAuthCallBack(onLoginCallBack: LoginCallBackHandler) {
        this.loginHandler = onLoginCallBack;
        this.msalApp.handleRedirectCallback(this.authCallBack);
    }

    authCallBack(error: MSAL.AuthError, response?: MSAL.AuthResponse) {
        if (error) {
            console.log(error);
        } else {
            let loggedIn = false;

            if (response?.tokenType === "id_token") {
                console.log("id_token acquired at: " + new Date().toString());
                sessionStorage.setItem("telematics.msal.idtoken", response?.idToken?.rawIdToken)
                loggedIn = true;
            } else if (response?.tokenType === "access_token") {
                console.log("access_token acquired at: " + new Date().toString());
            } else {
                console.log("token type is:" + response?.tokenType);
            }
          
            if (this.loginHandler) {
                this.loginHandler(loggedIn);
            }
        }
    }
}

export const AuthProvider = new AuthApi();