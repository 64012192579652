import React, { Fragment, ReactElement } from 'react';
import * as DashboardStore from '../../store/DashboardStore';
import { connect } from 'react-redux';
import { PrimaryNavigation } from "../common/PrimaryNavigation";
import DanlawVehicle from '../../models/DanlawVehicle';

interface DashboardVehicleProps {
    danlawVehicle: DanlawVehicle;
    vehiclesMap: any;
    vehicles: DanlawVehicle[];
}

interface DashboardVehicleState {
    vehiclesMap: any;
}

export enum VehicleChipStyles {
    Success = 'success',
    Warning = 'warning',
    Alert = 'alert',
    Neutral = 'neutral',
}

export enum VehicleChipMessages {
    Success = 'Success',
    Moving = 'Moving',
    Idling = 'Idling',
    BeingTowed = 'Being Towed',
    Parked = 'Parked',
    NotConnected = 'Not Connected',
}

export class DashboardVehicle extends React.Component<DashboardVehicleProps, DashboardVehicleState> {
    constructor(props: DashboardVehicleProps) {
        super(props);
        this.onContainerClick = this.onContainerClick.bind(this);
        this.state = {
            vehiclesMap: this.props.vehiclesMap
        };
    }

    componentDidMount(): void {
        
    }

    onContainerClick() {
        const { vehiclesMap, vehicles } = this.props;

        let markers = vehiclesMap.getMarkers();
        markers.forEach((marker: any) => {
            if (marker._element.id == "svgIcon-" + this.props.danlawVehicle.id) {
                marker._element.children[0].style.transform = "scale(1.2)";
                marker._element.children[1].style.transform = "scale(1.2)";
                vehiclesMap.flyTo({center: marker._lngLat, curve: 0.5, duration: 1000});
                // Fire a click event on the marker to open the popup
                document.getElementById("svgIcon-" + this.props.danlawVehicle.id)?.dispatchEvent(new MouseEvent("click"));
            }
        });
        let selectedVehicle = document.getElementById("vehicle-info" + this.props.danlawVehicle.id) as HTMLElement;
        selectedVehicle.style.background = "#F0F4FB";
        selectedVehicle.style.border = "1px solid #005CCC";

        vehicles.forEach((vehicleFromList: DanlawVehicle) => {
            let vehicle = document.getElementById("vehicle-info" + vehicleFromList.id) as HTMLElement;
            
            if (vehicle.id != selectedVehicle.id) {
                vehicle.style.removeProperty("background");
                vehicle.style.removeProperty("border");
            }
        });
    }
    
    render(): ReactElement {
        const { danlawVehicle } = this.props;
        
        let vehicleChipClass = "vehicle-chip-label chips chips-" + danlawVehicle.chipStatus;

        return (
            <>
                <div className="flexible-container vehicle-information-container" id={"vehicle-info" + danlawVehicle.id} onClick={this.onContainerClick}>
                    <div className={vehicleChipClass}>{danlawVehicle.chipMessage}</div>
                    <p className="text-left vehicle-info-vehicle"><b>{danlawVehicle.vehicleType}</b></p>
                    <p className="text-left vehicle-info-address">{danlawVehicle.nearestAddress?.addressLine1 + ", " + danlawVehicle.nearestAddress?.city + ", " + danlawVehicle.nearestAddress?.state}</p>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state: any, ownProps: any) => ({
    popups: state.dshs.popups,
});

export default connect(
    mapStateToProps,
    DashboardStore.actionCreators,
)(DashboardVehicle as any);