import React, { Fragment } from 'react';
import Button, { ButtonStyles } from '../common/Button';

export default (props: any) => (
    <Fragment>
        <div className="col-sm-5">
                <input
                id="searchPolicyInput"
                className="policy-number-input"
                style={{ width: '92%' }}
                type="text"
                placeholder="Policy No, DriverId/CustomerKey or VehicleId"
                value={props.policyNumber}
                onChange={props.onChangePolicyNumber}                
                />
            </div>            
            <div className="col-sm-2">
                <Button
                btnStyle={ButtonStyles.RightBlueButton}
                    onClick={props.onSearch}
                    title="Search"
                />
            </div>
    </Fragment>
);