import React, { Component, ChangeEvent } from 'react';

interface DatePickerProps {
    id?: string;
    name?: string;
    value?: string;
    className?: string;
    errorMessage?: string;
    label?: string;
    size?: number;
    maxLength?: number;
    disabled?: boolean;
    onChange: (event: any) => void;
    onBlur?: (event: any) => void;
    onFocus?: (event: any) => void;
}

export default class DatePicker extends Component<DatePickerProps> {
    regex = RegExp(/[0-9]|\//);

    setErrorClass() {
        const { errorMessage } = this.props;
        if (errorMessage) {
            return 'form-field--error';
        }
        return '';
    }

    renderErrorMessage() {
        const { errorMessage } = this.props;
        if (errorMessage) {
            return (
                <span
                    id='formMessage'
                    className='form-message'
                    style={{ display: 'block' }}
                >
                    {errorMessage}
                </span>
            );
        }
    }

    onChange = (e: ChangeEvent<HTMLInputElement>): void => {
        const { onChange } = this.props;

        if (!this.regex.test(e.target.value[e.target.value.length - 1])) {
            e.target.value = e.target.value.slice(0, e.target.value.length - 1);
        }

        onChange(e);
    }

    render() {
        const {
            label,
            disabled,
            id,
            className,
            name,
            value,
            maxLength,
            size,
            onFocus,
            onBlur
        } = this.props;
        return (
            <div className={'form-field ' + this.setErrorClass()}>
                <label htmlFor='field5' className='text'>{label}</label>
                <input
                    id={id}
                    name={name}
                    type='tel'
                    className={'date date-datepicker ' + className}
                    data-validate='callback_date|required'
                    placeholder='MM/DD/YYYY'
                    disabled={disabled}
                    maxLength={maxLength}
                    size={size}
                    value={value}
                    onChange={(e) => this.onChange(e)}
                    onFocus={onFocus}
                    onBlur={onBlur}
                />
                {this.renderErrorMessage()}
            </div>
        );
    }
}