import React, { ReactElement } from "react";
import { VehicleChipMessages } from "../common/DashboardVehicle";

export function RelevantIcon(id: string, vehicleChipMessage: string): ReactElement {
    switch (vehicleChipMessage) {
        case VehicleChipMessages.Idling:
            return IdlingIcon(id);
        case VehicleChipMessages.Moving:
            return MovingIcon(id);
        case VehicleChipMessages.BeingTowed:
            return TowedIcon(id);
        case VehicleChipMessages.Parked:
            return ParkedIcon(id);
        default:
            return RegularIcon(id);
    }
}

export function AllIcons(): VehicleChipMessages[] {
    return [VehicleChipMessages.Idling, VehicleChipMessages.BeingTowed, VehicleChipMessages.Moving, VehicleChipMessages.NotConnected, VehicleChipMessages.Parked, VehicleChipMessages.Success];
} 

export function RegularIcon(id: string): ReactElement {
    return (
        <svg width="10%" height="10%" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg" id={id} className="mapMarker">
            <g filter="url(#filter0_d_65_8892)"  className={id}>
            <circle cx="32.4463" cy="28.4462" r="27" fill="#004B9B" stroke="white" strokeWidth="2"/>
            <path d="M47.8464 27.5618C47.8464 26.6106 47.0602 25.8299 46.1008 25.8299H44.2864C44.1545 24.6341 43.1676 17.7258 37.9994 17.7258H26.7779C21.6977 17.7285 20.7163 24.6368 20.5844 25.8326H18.792C17.8326 25.8326 17.0464 26.6106 17.0464 27.5645V29.5026H19.2758C19.0999 29.9397 18.9899 30.4317 18.9899 31.0723V38.6101C18.9899 39.3468 19.5452 39.9901 20.3809 39.9901H23.7183C24.4605 39.9901 25.1093 39.3468 25.1093 38.6101V37.7001H39.7615V38.6101C39.7615 39.3468 40.4103 39.9901 41.1525 39.9901H44.4898C45.2321 39.9901 45.9743 39.3468 45.9743 38.6101V31.0723C45.9743 30.4317 45.8616 29.9397 45.6417 29.5026H47.8464V27.5645V27.5618ZM22.9788 34.1979C22.1431 34.1979 21.2166 33.4611 21.2166 32.4495C21.2166 31.4379 22.1431 30.6104 22.9788 30.6104C23.9987 30.6104 24.8344 31.4379 24.8344 32.4495C24.8344 33.4611 23.9987 34.1979 22.9788 34.1979ZM22.7946 26.5611C23.3499 24.076 23.721 19.8453 26.7807 19.8453H38.0022C41.1553 19.8453 41.5264 24.0787 42.1752 26.5611C36.6111 28.0346 28.2651 28.0346 22.7946 26.5611ZM41.804 34.1979C40.8776 34.1979 40.0419 33.4611 40.0419 32.4495C40.0419 31.4379 40.8776 30.6104 41.804 30.6104C42.9174 30.6104 43.6596 31.4379 43.6596 32.4495C43.6596 33.4611 42.9174 34.1979 41.804 34.1979Z" fill="white"/>
            </g>
            <defs>
                <filter id="filter0_d_65_8892" x="0.446289" y="0.446228" width="64" height="64" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="4"/>
                    <feGaussianBlur stdDeviation="2"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_65_8892"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_65_8892" result="shape"/>
                </filter>
            </defs>
        </svg>
    )
}

export function MovingIcon(id: string): ReactElement {
    return (
        <svg width="10%" height="10%" viewBox="0 0 82 83" fill="none" xmlns="http://www.w3.org/2000/svg" id={id} className="mapMarker">
            <g filter="url(#filter0_d_3258_13871)"  className={id}>
                <path d="M71.621 41.0475C71.621 57.7911 57.8267 71.3645 40.8105 71.3645C23.7943 71.3645 10 57.7911 10 41.0475C10 24.3039 23.7943 10.7305 40.8105 10.7305C57.8267 10.7305 71.621 24.3039 71.621 41.0475Z" fill="#004B9B"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M40.8105 69.4396C56.7463 69.4396 69.6648 56.728 69.6648 41.0475C69.6648 25.3669 56.7463 12.6554 40.8105 12.6554C24.8747 12.6554 11.9562 25.3669 11.9562 41.0475C11.9562 56.728 24.8747 69.4396 40.8105 69.4396ZM40.8105 71.3645C57.8267 71.3645 71.621 57.7911 71.621 41.0475C71.621 24.3039 57.8267 10.7305 40.8105 10.7305C23.7943 10.7305 10 24.3039 10 41.0475C10 57.7911 23.7943 71.3645 40.8105 71.3645Z" fill="white"/>
                <path d="M55.5879 38.6218C55.5879 37.7227 54.8326 36.9847 53.911 36.9847H52.168C52.0412 35.8543 51.0932 29.3242 46.1284 29.3242H35.3485C30.4683 29.3268 29.5255 35.8569 29.3988 36.9873H27.6769C26.7553 36.9873 26 37.7227 26 38.6244V40.4563H28.1417C27.9727 40.8695 27.8671 41.3346 27.8671 41.9401V49.0653C27.8671 49.7617 28.4005 50.3697 29.2033 50.3697H32.4093C33.1223 50.3697 33.7456 49.7617 33.7456 49.0653V48.2052H47.8212V49.0653C47.8212 49.7617 48.4444 50.3697 49.1575 50.3697H52.3634C53.0765 50.3697 53.7895 49.7617 53.7895 49.0653V41.9401C53.7895 41.3346 53.6812 40.8695 53.4699 40.4563H55.5879V38.6244V38.6218ZM31.6989 44.8946C30.8961 44.8946 30.0061 44.1982 30.0061 43.242C30.0061 42.2857 30.8961 41.5035 31.6989 41.5035C32.6787 41.5035 33.4815 42.2857 33.4815 43.242C33.4815 44.1982 32.6787 44.8946 31.6989 44.8946ZM31.522 37.6759C32.0554 35.3268 32.4119 31.3277 35.3512 31.3277H46.1311C49.1601 31.3277 49.5166 35.3294 50.1398 37.6759C44.7948 39.0687 36.7772 39.0687 31.522 37.6759ZM49.7833 44.8946C48.8934 44.8946 48.0906 44.1982 48.0906 43.242C48.0906 42.2857 48.8934 41.5035 49.7833 41.5035C50.8529 41.5035 51.5659 42.2857 51.5659 43.242C51.5659 44.1982 50.8529 44.8946 49.7833 44.8946Z" fill="white"/>
            </g>
            <g filter="url(#filter1_d_3258_13871)" className={id}>
                <path d="M75.0001 62.7837C75.0001 69.1622 69.7451 74.3331 63.2627 74.3331C56.7804 74.3331 51.5254 69.1622 51.5254 62.7837C51.5254 56.4052 56.7804 51.2344 63.2627 51.2344C69.7451 51.2344 75.0001 56.4052 75.0001 62.7837Z" fill="#22841F"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M63.2627 72.4082C68.6647 72.4082 73.0439 68.0991 73.0439 62.7837C73.0439 57.4683 68.6647 53.1593 63.2627 53.1593C57.8608 53.1593 53.4816 57.4683 53.4816 62.7837C53.4816 68.0991 57.8608 72.4082 63.2627 72.4082ZM63.2627 74.3331C69.7451 74.3331 75.0001 69.1622 75.0001 62.7837C75.0001 56.4052 69.7451 51.2344 63.2627 51.2344C56.7804 51.2344 51.5254 56.4052 51.5254 62.7837C51.5254 69.1622 56.7804 74.3331 63.2627 74.3331Z" fill="white"/>
                <path d="M61.6131 64.1497L58.5577 62.9386C58.3733 62.8644 58.2288 62.7471 58.1242 62.5866C58.0193 62.4258 57.9668 62.2506 57.9668 62.0611C57.9668 61.8716 58.0213 61.7005 58.1303 61.548C58.2392 61.3957 58.3817 61.2866 58.5577 61.2207L66.6676 58.1681C66.8268 58.1021 66.9819 58.0918 67.1328 58.1369C67.2837 58.1824 67.4094 58.2546 67.51 58.3534C67.6106 58.4523 67.6818 58.5738 67.7237 58.7178C67.7656 58.8621 67.7531 59.0126 67.686 59.1691L64.593 67.1406C64.5175 67.3383 64.4002 67.4866 64.2409 67.5855C64.0816 67.6844 63.9098 67.7338 63.7254 67.7338C63.541 67.7338 63.3671 67.6844 63.2039 67.5855C63.0402 67.4866 62.9165 67.3425 62.8327 67.153L61.6131 64.1497Z" fill="white"/>
            </g>
            <defs>
                <filter id="filter0_d_3258_13871" x="5" y="8.73047" width="71.6211" height="70.6328" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="3"/>
                    <feGaussianBlur stdDeviation="2.5"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3258_13871"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3258_13871" result="shape"/>
                </filter>
                <filter id="filter1_d_3258_13871" x="46.5254" y="49.2344" width="33.4746" height="33.0977" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="3"/>
                    <feGaussianBlur stdDeviation="2.5"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3258_13871"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3258_13871" result="shape"/>
                </filter>
            </defs>
        </svg>
    )
}

export function IdlingIcon(id: string): ReactElement {
    return (
        <svg width="10%" height="10%" viewBox="0 0 82 83" fill="none" xmlns="http://www.w3.org/2000/svg" id={id} className="mapMarker">
            <g filter="url(#filter0_d_3258_13870)"  className={id}>
                <path d="M71.621 41.0475C71.621 57.7911 57.8267 71.3645 40.8105 71.3645C23.7943 71.3645 10 57.7911 10 41.0475C10 24.3039 23.7943 10.7305 40.8105 10.7305C57.8267 10.7305 71.621 24.3039 71.621 41.0475Z" fill="#004B9B"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M40.8105 69.4396C56.7463 69.4396 69.6648 56.728 69.6648 41.0475C69.6648 25.3669 56.7463 12.6554 40.8105 12.6554C24.8747 12.6554 11.9562 25.3669 11.9562 41.0475C11.9562 56.728 24.8747 69.4396 40.8105 69.4396ZM40.8105 71.3645C57.8267 71.3645 71.621 57.7911 71.621 41.0475C71.621 24.3039 57.8267 10.7305 40.8105 10.7305C23.7943 10.7305 10 24.3039 10 41.0475C10 57.7911 23.7943 71.3645 40.8105 71.3645Z" fill="white"/>
                <path d="M55.5879 38.6218C55.5879 37.7227 54.8326 36.9847 53.911 36.9847H52.168C52.0412 35.8543 51.0932 29.3242 46.1284 29.3242H35.3485C30.4683 29.3268 29.5255 35.8569 29.3988 36.9873H27.6769C26.7553 36.9873 26 37.7227 26 38.6244V40.4563H28.1417C27.9727 40.8695 27.8671 41.3346 27.8671 41.9401V49.0653C27.8671 49.7617 28.4005 50.3697 29.2033 50.3697H32.4093C33.1223 50.3697 33.7456 49.7617 33.7456 49.0653V48.2052H47.8212V49.0653C47.8212 49.7617 48.4444 50.3697 49.1575 50.3697H52.3634C53.0765 50.3697 53.7895 49.7617 53.7895 49.0653V41.9401C53.7895 41.3346 53.6812 40.8695 53.4699 40.4563H55.5879V38.6244V38.6218ZM31.6989 44.8946C30.8961 44.8946 30.0061 44.1982 30.0061 43.242C30.0061 42.2857 30.8961 41.5035 31.6989 41.5035C32.6787 41.5035 33.4815 42.2857 33.4815 43.242C33.4815 44.1982 32.6787 44.8946 31.6989 44.8946ZM31.522 37.6759C32.0554 35.3268 32.4119 31.3277 35.3512 31.3277H46.1311C49.1601 31.3277 49.5166 35.3294 50.1398 37.6759C44.7948 39.0687 36.7772 39.0687 31.522 37.6759ZM49.7833 44.8946C48.8934 44.8946 48.0906 44.1982 48.0906 43.242C48.0906 42.2857 48.8934 41.5035 49.7833 41.5035C50.8529 41.5035 51.5659 42.2857 51.5659 43.242C51.5659 44.1982 50.8529 44.8946 49.7833 44.8946Z" fill="white"/>
            </g>
            <g filter="url(#filter1_d_3258_13870)" className={id}>
                <path d="M75.0001 62.7837C75.0001 69.1622 69.7451 74.3331 63.2627 74.3331C56.7804 74.3331 51.5254 69.1622 51.5254 62.7837C51.5254 56.4052 56.7804 51.2344 63.2627 51.2344C69.7451 51.2344 75.0001 56.4052 75.0001 62.7837Z" fill="#F3A241"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M63.2627 72.4082C68.6647 72.4082 73.0439 68.0991 73.0439 62.7837C73.0439 57.4683 68.6647 53.1593 63.2627 53.1593C57.8608 53.1593 53.4816 57.4683 53.4816 62.7837C53.4816 68.0991 57.8608 72.4082 63.2627 72.4082ZM63.2627 74.3331C69.7451 74.3331 75.0001 69.1622 75.0001 62.7837C75.0001 56.4052 69.7451 51.2344 63.2627 51.2344C56.7804 51.2344 51.5254 56.4052 51.5254 62.7837C51.5254 69.1622 56.7804 74.3331 63.2627 74.3331Z" fill="white"/>
                <path d="M64.3505 64.5591C64.4816 64.6901 64.6394 64.7557 64.8239 64.7557C65.0081 64.7557 65.1698 64.6861 65.3091 64.5468C65.4483 64.4157 65.518 64.2579 65.518 64.0734C65.518 63.8892 65.4483 63.7275 65.3091 63.5882L64.0679 62.3471V60.9093C64.0679 60.7208 64.0023 60.563 63.8713 60.4359C63.7402 60.3091 63.5804 60.2456 63.392 60.2456C63.2036 60.2456 63.0438 60.3112 62.9127 60.4423C62.7816 60.5734 62.7161 60.7331 62.7161 60.9215V62.5928C62.7161 62.6993 62.7346 62.7977 62.7716 62.8878C62.8083 62.9779 62.8677 63.0639 62.9496 63.1458L64.3505 64.5591ZM63.392 67.9754C62.6465 67.9754 61.9501 67.8361 61.3029 67.5576C60.6557 67.279 60.0923 66.9002 59.6129 66.4211C59.1338 65.9417 58.755 65.3783 58.4764 64.7311C58.1979 64.0839 58.0586 63.3875 58.0586 62.642C58.0586 61.8965 58.1979 61.2001 58.4764 60.5529C58.755 59.9057 59.1338 59.3423 59.6129 58.8629C60.0923 58.3838 60.6557 58.005 61.3029 57.7264C61.9501 57.4479 62.6465 57.3086 63.392 57.3086C64.1375 57.3086 64.8339 57.4479 65.4811 57.7264C66.1283 58.005 66.6917 58.3838 67.1711 58.8629C67.6502 59.3423 68.029 59.9057 68.3076 60.5529C68.5861 61.2001 68.7254 61.8965 68.7254 62.642C68.7254 63.3875 68.5861 64.0839 68.3076 64.7311C68.029 65.3783 67.6502 65.9417 67.1711 66.4211C66.6917 66.9002 66.1283 67.279 65.4811 67.5576C64.8339 67.8361 64.1375 67.9754 63.392 67.9754Z" fill="white"/>
            </g>
            <defs>
                <filter id="filter0_d_3258_13870" x="5" y="8.73047" width="71.6211" height="70.6328" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="3"/>
                    <feGaussianBlur stdDeviation="2.5"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3258_13870"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3258_13870" result="shape"/>
                </filter>
                <filter id="filter1_d_3258_13870" x="46.5254" y="49.2344" width="33.4746" height="33.0977" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="3"/>
                    <feGaussianBlur stdDeviation="2.5"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3258_13870"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3258_13870" result="shape"/>
                </filter>
            </defs>
        </svg>
    )
}

export function ParkedIcon(id: string): ReactElement {
    return (
        <svg width="10%" height="10%" viewBox="0 0 82 83" fill="none" xmlns="http://www.w3.org/2000/svg" id={id} className="mapMarker">
            <g filter="url(#filter0_d_3258_13868)"  className={id}>
                <path d="M71.621 41.0475C71.621 57.7911 57.8267 71.3645 40.8105 71.3645C23.7943 71.3645 10 57.7911 10 41.0475C10 24.3039 23.7943 10.7305 40.8105 10.7305C57.8267 10.7305 71.621 24.3039 71.621 41.0475Z" fill="#004B9B"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M40.8105 69.4396C56.7463 69.4396 69.6648 56.728 69.6648 41.0475C69.6648 25.3669 56.7463 12.6554 40.8105 12.6554C24.8747 12.6554 11.9562 25.3669 11.9562 41.0475C11.9562 56.728 24.8747 69.4396 40.8105 69.4396ZM40.8105 71.3645C57.8267 71.3645 71.621 57.7911 71.621 41.0475C71.621 24.3039 57.8267 10.7305 40.8105 10.7305C23.7943 10.7305 10 24.3039 10 41.0475C10 57.7911 23.7943 71.3645 40.8105 71.3645Z" fill="white"/>
                <path d="M55.5879 38.6218C55.5879 37.7227 54.8326 36.9847 53.911 36.9847H52.168C52.0412 35.8543 51.0932 29.3242 46.1284 29.3242H35.3485C30.4683 29.3268 29.5255 35.8569 29.3988 36.9873H27.6769C26.7553 36.9873 26 37.7227 26 38.6244V40.4563H28.1417C27.9727 40.8695 27.8671 41.3346 27.8671 41.9401V49.0653C27.8671 49.7617 28.4005 50.3697 29.2033 50.3697H32.4093C33.1223 50.3697 33.7456 49.7617 33.7456 49.0653V48.2052H47.8212V49.0653C47.8212 49.7617 48.4444 50.3697 49.1575 50.3697H52.3634C53.0765 50.3697 53.7895 49.7617 53.7895 49.0653V41.9401C53.7895 41.3346 53.6812 40.8695 53.4699 40.4563H55.5879V38.6244V38.6218ZM31.6989 44.8946C30.8961 44.8946 30.0061 44.1982 30.0061 43.242C30.0061 42.2857 30.8961 41.5035 31.6989 41.5035C32.6787 41.5035 33.4815 42.2857 33.4815 43.242C33.4815 44.1982 32.6787 44.8946 31.6989 44.8946ZM31.522 37.6759C32.0554 35.3268 32.4119 31.3277 35.3512 31.3277H46.1311C49.1601 31.3277 49.5166 35.3294 50.1398 37.6759C44.7948 39.0687 36.7772 39.0687 31.522 37.6759ZM49.7833 44.8946C48.8934 44.8946 48.0906 44.1982 48.0906 43.242C48.0906 42.2857 48.8934 41.5035 49.7833 41.5035C50.8529 41.5035 51.5659 42.2857 51.5659 43.242C51.5659 44.1982 50.8529 44.8946 49.7833 44.8946Z" fill="white"/>
            </g>
            <g filter="url(#filter1_d_3258_13868)" className={id}>
                <path d="M75.0001 62.7837C75.0001 69.1622 69.7451 74.3331 63.2627 74.3331C56.7804 74.3331 51.5254 69.1622 51.5254 62.7837C51.5254 56.4052 56.7804 51.2344 63.2627 51.2344C69.7451 51.2344 75.0001 56.4052 75.0001 62.7837Z" fill="#616161"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M63.2627 72.4082C68.6647 72.4082 73.0439 68.0991 73.0439 62.7837C73.0439 57.4683 68.6647 53.1593 63.2627 53.1593C57.8608 53.1593 53.4816 57.4683 53.4816 62.7837C53.4816 68.0991 57.8608 72.4082 63.2627 72.4082ZM63.2627 74.3331C69.7451 74.3331 75.0001 69.1622 75.0001 62.7837C75.0001 56.4052 69.7451 51.2344 63.2627 51.2344C56.7804 51.2344 51.5254 56.4052 51.5254 62.7837C51.5254 69.1622 56.7804 74.3331 63.2627 74.3331Z" fill="white"/>
                <path d="M62.631 64.1789V65.7437C62.631 66.0939 62.504 66.3909 62.25 66.6345C61.996 66.8782 61.6871 67 61.3232 67C60.9593 67 60.6478 66.8759 60.3887 66.6276C60.1296 66.3793 60 66.0809 60 65.7322V59.2563C60 58.9061 60.1294 58.6091 60.3882 58.3655C60.6469 58.1218 60.958 58 61.3214 58H63.5952C64.5873 58 65.4028 58.3027 66.0417 58.908C66.6806 59.5133 67 60.2424 67 61.0952C67 61.948 66.6806 62.6751 66.0417 63.2766C65.4028 63.8782 64.5873 64.1789 63.5952 64.1789H62.631ZM62.631 62.0089H63.4405C63.7024 62.0089 63.9266 61.9194 64.1131 61.7405C64.2996 61.5615 64.3929 61.3464 64.3929 61.0952C64.3929 60.8439 64.2996 60.6288 64.1131 60.4499C63.9266 60.2709 63.7024 60.1815 63.4405 60.1815H62.631V62.0089Z" fill="white"/>
            </g>
            <defs>
                <filter id="filter0_d_3258_13868" x="5" y="8.73047" width="71.6211" height="70.6328" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="3"/>
                    <feGaussianBlur stdDeviation="2.5"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3258_13868"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3258_13868" result="shape"/>
                </filter>
                <filter id="filter1_d_3258_13868" x="46.5254" y="49.2344" width="33.4746" height="33.0977" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="3"/>
                    <feGaussianBlur stdDeviation="2.5"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3258_13868"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3258_13868" result="shape"/>
                </filter>
            </defs>
        </svg>
    )
}

export function TowedIcon(id: string): ReactElement {
    return (
        <svg width="10%" height="10%" viewBox="0 0 82 83" fill="none" xmlns="http://www.w3.org/2000/svg" id={id}>
            <g filter="url(#filter0_d_3258_13869)"  className={id}>
                <path d="M71.621 41.0475C71.621 57.7911 57.8267 71.3645 40.8105 71.3645C23.7943 71.3645 10 57.7911 10 41.0475C10 24.3039 23.7943 10.7305 40.8105 10.7305C57.8267 10.7305 71.621 24.3039 71.621 41.0475Z" fill="#004B9B"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M40.8105 69.4396C56.7463 69.4396 69.6648 56.728 69.6648 41.0475C69.6648 25.3669 56.7463 12.6554 40.8105 12.6554C24.8747 12.6554 11.9562 25.3669 11.9562 41.0475C11.9562 56.728 24.8747 69.4396 40.8105 69.4396ZM40.8105 71.3645C57.8267 71.3645 71.621 57.7911 71.621 41.0475C71.621 24.3039 57.8267 10.7305 40.8105 10.7305C23.7943 10.7305 10 24.3039 10 41.0475C10 57.7911 23.7943 71.3645 40.8105 71.3645Z" fill="white"/>
                <path d="M55.5879 38.6218C55.5879 37.7227 54.8326 36.9847 53.911 36.9847H52.168C52.0412 35.8543 51.0932 29.3242 46.1284 29.3242H35.3485C30.4683 29.3268 29.5255 35.8569 29.3988 36.9873H27.6769C26.7553 36.9873 26 37.7227 26 38.6244V40.4563H28.1417C27.9727 40.8695 27.8671 41.3346 27.8671 41.9401V49.0653C27.8671 49.7617 28.4005 50.3697 29.2033 50.3697H32.4093C33.1223 50.3697 33.7456 49.7617 33.7456 49.0653V48.2052H47.8212V49.0653C47.8212 49.7617 48.4444 50.3697 49.1575 50.3697H52.3634C53.0765 50.3697 53.7895 49.7617 53.7895 49.0653V41.9401C53.7895 41.3346 53.6812 40.8695 53.4699 40.4563H55.5879V38.6244V38.6218ZM31.6989 44.8946C30.8961 44.8946 30.0061 44.1982 30.0061 43.242C30.0061 42.2857 30.8961 41.5035 31.6989 41.5035C32.6787 41.5035 33.4815 42.2857 33.4815 43.242C33.4815 44.1982 32.6787 44.8946 31.6989 44.8946ZM31.522 37.6759C32.0554 35.3268 32.4119 31.3277 35.3512 31.3277H46.1311C49.1601 31.3277 49.5166 35.3294 50.1398 37.6759C44.7948 39.0687 36.7772 39.0687 31.522 37.6759ZM49.7833 44.8946C48.8934 44.8946 48.0906 44.1982 48.0906 43.242C48.0906 42.2857 48.8934 41.5035 49.7833 41.5035C50.8529 41.5035 51.5659 42.2857 51.5659 43.242C51.5659 44.1982 50.8529 44.8946 49.7833 44.8946Z" fill="white"/>
            </g>
            <g filter="url(#filter1_d_3258_13869)" className={id}>
                <path d="M75.0001 62.7837C75.0001 69.1622 69.7451 74.3331 63.2627 74.3331C56.7804 74.3331 51.5254 69.1622 51.5254 62.7837C51.5254 56.4052 56.7804 51.2344 63.2627 51.2344C69.7451 51.2344 75.0001 56.4052 75.0001 62.7837Z" fill="#E00B20"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M63.2627 72.4082C68.6647 72.4082 73.0439 68.0991 73.0439 62.7837C73.0439 57.4683 68.6647 53.1593 63.2627 53.1593C57.8608 53.1593 53.4816 57.4683 53.4816 62.7837C53.4816 68.0991 57.8608 72.4082 63.2627 72.4082ZM63.2627 74.3331C69.7451 74.3331 75.0001 69.1622 75.0001 62.7837C75.0001 56.4052 69.7451 51.2344 63.2627 51.2344C56.7804 51.2344 51.5254 56.4052 51.5254 62.7837C51.5254 69.1622 56.7804 74.3331 63.2627 74.3331Z" fill="white"/>
                <path d="M63.3929 67.9754C62.9595 67.9754 62.5898 67.8346 62.2838 67.5529C61.9774 67.2709 61.8242 66.9304 61.8242 66.5315C61.8242 66.1326 61.9774 65.7923 62.2838 65.5106C62.5898 65.2286 62.9595 65.0876 63.3929 65.0876C63.8262 65.0876 64.1961 65.2286 64.5025 65.5106C64.8085 65.7923 64.9615 66.1326 64.9615 66.5315C64.9615 66.9304 64.8085 67.2709 64.5025 67.5529C64.1961 67.8346 63.8262 67.9754 63.3929 67.9754ZM63.3929 64.0469C63.0254 64.0469 62.7098 63.9255 62.446 63.6827C62.1822 63.4398 62.0503 63.1493 62.0503 62.8111V58.5444C62.0503 58.2062 62.1822 57.9156 62.446 57.6728C62.7098 57.43 63.0254 57.3086 63.3929 57.3086C63.7603 57.3086 64.0759 57.43 64.3397 57.6728C64.6035 57.9156 64.7354 58.2062 64.7354 58.5444V62.8111C64.7354 63.1493 64.6035 63.4398 64.3397 63.6827C64.0759 63.9255 63.7603 64.0469 63.3929 64.0469Z" fill="white"/>
            </g>
            <defs>
                <filter id="filter0_d_3258_13869" x="5" y="8.73047" width="71.6211" height="70.6328" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="3"/>
                    <feGaussianBlur stdDeviation="2.5"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3258_13869"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3258_13869" result="shape"/>
                </filter>
                <filter id="filter1_d_3258_13869" x="46.5254" y="49.2344" width="33.4746" height="33.0977" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="3"/>
                    <feGaussianBlur stdDeviation="2.5"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3258_13869"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3258_13869" result="shape"/>
                </filter>
            </defs>
        </svg>
    )
}