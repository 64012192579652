import DriverPolicyUpdate from '../models/Driver';
import VehiclePolicyUpdate from '../models/Vehicle';
import { EnrollmentPolicyUpdate } from '../models/EnrollmentPolicyUpdate';
import UCMStates from '../models/UCMState';
import { UpdatePolicyInfoRequest } from '../models/PolicyInfo';
import { EnrollPolicyRequest } from '../models/EnrollPolicyRequest';
import { AddPolicyInfoRequest } from '../models/AddPolicyInfoRequest';
import { ReplaceMobileNumbersRequest } from '../models/ReplaceMobileNumbersRequest';
import { AuthProvider } from './authApi';
import BatchJobSettings from '../models/BatchJobSettings';
import { TagReplacementRequest } from '../models/TagReplacementRequest';

export default class TelematicsApi {

    static enrollPolicy(enrollPolicyRequest: EnrollPolicyRequest) {
        if (enrollPolicyRequest.vehicles != undefined) {
            var i = enrollPolicyRequest.vehicles?.length;
            while (i--) {
                if (enrollPolicyRequest.vehicles[i].year.match(/\D/g) != null) {
                    enrollPolicyRequest.vehicles.splice(i, 1);
                }
            }
        }

        const requestOptions = this.createPostObject(enrollPolicyRequest);
        return TelematicsFetch('/ui/api/telematics/EnrollPolicy', requestOptions);
    }

    static addPolicyInfoFromCproa(addPolicyInfoRequest: AddPolicyInfoRequest): Promise<any> {
        const requestOptions = this.createPostObject(addPolicyInfoRequest);
        return TelematicsFetch('/ui/api/telematics/AddPolicyInfoFromCproa?policyNumber=' + addPolicyInfoRequest.policyNumber, requestOptions);
    }

    static getPolicyByPolicyNumber(policyNumber: string, searchOption: string): Promise<any> {
        const requestOptions = this.createGetObject();
        return TelematicsFetch('/ui/api/telematics/search?policyNumber=' + policyNumber + '&searchOption=' + searchOption, requestOptions);
    }

    static getPolicyWithoutCproaByPolicyNumber(policyNumber: string, searchOption: string): Promise<any> {
        const requestOptions = this.createGetObject();
        return TelematicsFetch('/ui/api/telematics/searchWithoutCproa?policyNumber=' + policyNumber + '&searchOption=' + searchOption, requestOptions);
    }

    static downloadCsv(date: string): Promise<any> {
        const requestOptions = this.createGetObject();
        return TelematicsFileFetch('/ui/api/puma/DownloadUnderWritingReport?date=' + date, requestOptions);
    }

    static bulkPolicyUpload(policyList: string[], userComments?: string): Promise<any> {
        const requestOptions = this.createPostObject(policyList);
        return TelematicsFetch('/ui/api/telematics/BulkPolicyUpload?userComments=' + userComments, requestOptions);
    }

    static upsertDriver(policy: DriverPolicyUpdate) {
        const requestOptions = this.createPostObject(policy);
        return TelematicsFetch('/ui/api/telematics/addUpdateDriver', requestOptions);
    }

    static upsertVehicle(policy: VehiclePolicyUpdate) {
        const requestOptions = this.createPostObject(policy);
        return TelematicsFetch('/ui/api/telematics/addUpdateVehicle', requestOptions);
    }

    static removeVehicle(policy: VehiclePolicyUpdate) {
        const requestOptions = this.createPostObject(policy);
        return TelematicsFetch('/ui/api/telematics/removeVehicle', requestOptions);
    }

    static uploadFileToBlob(formData: any, fileName: string, fileDate: string) {
        const requestOptions = this.createFormDataPostObject(formData);
        return TelematicsFetch(`/api/usercontrol/UploadBlobFile?fileName=${fileName}&fileDate=${fileDate}`, requestOptions);
    }

    static getBatchJobSettings() {
        const requestOptions = this.createGetObject();
        return TelematicsFetch('/api/usercontrol/getBatchJobSettings', requestOptions);
    }

    static putBatchJobSettings(batchJobs: BatchJobSettings) {
        const requestOptions = this.createPutObject({ batchJobs });
        return TelematicsFetch('/api/usercontrol/putBatchJobSettings', requestOptions);
    }

    static getStatesVolInVol() {
        const requestOptions = this.createGetObject();
        return TelematicsFetch('/api/stateucm', requestOptions);
    }

    static getStateHistory(state: string) {
        const requestOptions = this.createGetObject();
        return TelematicsFetch('/api/stateucm/getStateHistory?state=' + state, requestOptions);
    }

    static upsertPolicyEnrollment(policy: EnrollmentPolicyUpdate) {
        const requestOptions = this.createPostObject(policy);
        return TelematicsFetch('/ui/api/telematics/UnenrollPolicy', requestOptions);
    }

    static updateVoluntaryStates(states: UCMStates) {
        const requestOptions = this.createPutObject({ states });
        return TelematicsFetch('/api/stateucm', requestOptions);
    }

    static updatePolicyInformation(policy: UpdatePolicyInfoRequest): Promise<any> {
        const requestOptions = this.createPostObject(policy);
        return TelematicsFetch('/ui/api/telematics/updatePolicyInformation?policyNumber=' + policy.policyNumber, requestOptions);
    }

    static startDriverEnrollmentIncompleteJob() {
        const requestOptions = this.createGetObject();
        return TelematicsFetch('/api/jobs/driverEnrollmentIncomplete', requestOptions);
    }

    static startVehicleDataNotReceivedJob() {
        const requestOptions = this.createGetObject();
        return TelematicsFetch('/api/jobs/vehicleDataNotReceived', requestOptions);
    }

    static startDriverHeartbeatNotReceivedJob() {
        const requestOptions = this.createGetObject();
        return TelematicsFetch('/api/jobs/driverHeartbeatNotReceived', requestOptions);
    }

    static startTagOnlyTripsJob() {
        const requestOptions = this.createGetObject();
        return TelematicsFetch('/api/jobs/tagOnlyTrips', requestOptions);
    }

    static startVehicleEnrollmentIncomplete() {
        const requestOptions = this.createGetObject();
        return TelematicsFetch('/api/jobs/vehicleEnrollmentIncomplete', requestOptions);
    }

    static startVendorDriverSummaryJob() {
        const requestOptions = this.createGetObject();
        return TelematicsFetch('/api/jobs/vendorDriverSummary', requestOptions);
    }

    static startVendorTagTripSummaryJob() {
        const requestOptions = this.createGetObject();
        return TelematicsFetch('/api/jobs/vendorTagTripSummary', requestOptions);
    }

    static deleteCmtData(removalDate: string) {
        const requestOptions = this.createPostObject(removalDate);
        return TelematicsFetch('/api/jobs/DeleteOldTelematicsData?inputDate=' + removalDate, requestOptions);
    }

    static getDashcamTelematicsData(policyNumber: string) {
        const requestOptions = this.createGetObject();
        return DashcamFetch('/ui/api/Telematics/GetDashcamData?policyNumber=' + policyNumber, requestOptions);
    }

    static requestDashcamShippingData(policyNumber: string) {
        const requestOptions = this.createGetObject();
        return DashcamFetch('/ui/api/Telematics/RequestDashcamShippingData?policyNumber=' + policyNumber, requestOptions);
    }

    static getVendorInformation(policyNumber: string) {
        const requestOptions = this.createGetObject();
        return TelematicsFetch('/ui/api/Telematics/getVendorInformation?policyNumber=' + policyNumber, requestOptions);
    }

    static getVehicleTagData(policyNumber: string) {
        const requestOptions = this.createGetObject();
        return TelematicsFetch('/ui/api/Telematics/GetVehicleTagData?policyNumber=' + policyNumber, requestOptions);
    }

    static replaceMobileNumbers(replaceMobileNumbersRequest: ReplaceMobileNumbersRequest) {
        const requestOptions = this.createPostObject(replaceMobileNumbersRequest);
        return TelematicsFetch('/ui/api/Telematics/ReplaceMobileNumbers', requestOptions);
    }

    static tagReplacement(tagReplacementRequest : TagReplacementRequest) {
        const requestOptions = this.createPostObject(tagReplacementRequest);
        return TelematicsFetchResponseWithoutJson('/ui/api/Telematics/TagReplacement', requestOptions);
    }

    static getClips(serialNumber: string) {
        const requestOptions = this.createGetObject();
        return TelematicsFetch('/ui/api/Telematics/GetClips?serialNumber=' + serialNumber, requestOptions);
    }

    static createGetObject<T>(): any {
        return {
            method: 'GET',
            headers: {              
                'Authorization': 'Bearer ' + AuthProvider.getToken()
            },
        };
    }

    static createPostObject<T>(data: T): any {
        return {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthProvider.getToken()
            },
            body: JSON.stringify(data),
        };
    }

    static createFormDataPostObject<T>(blobUploadRequest: T): any {
        return {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + AuthProvider.getToken()
            },
            body: blobUploadRequest,
        };
    }

    static createEmptyPostObject(): any {
        return {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthProvider.getToken()
            },
        };
    }

    static createPutObject<T>(data: T): any {
        return {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + AuthProvider.getToken()
            },
            body: JSON.stringify(data)
        }
    }
}

const TelematicsFetch = (url: string, requestOptions: any) => {
    return fetch(url, requestOptions)
        .then(res => {
            if(res.status == 200){
                return res.json();
            }
            else if(res.status == 201 || res.status == 206){
                return res;
            }
            else {
                return Promise.reject({
                    status: res.status,
                    statusText: res.statusText,
                    url,
                    requestOptions
                });
            }
        });
}

const TelematicsFetchResponseWithoutJson = (url: string, requestOptions: any) => {
    return fetch(url, requestOptions)
        .then(res => {
            if(res.status == 200){
                return res;
            }
            else {
                return Promise.reject({
                    status: res.status,
                    statusText: res.statusText,
                    url,
                    requestOptions
                });
            }
        });
}

const DashcamFetch = (url: string, requestOptions: any) => {
    return fetch(url, requestOptions)
        .then(res => {
            if(res.status == 200){
                return res.json();
            }
            else if(res.status == 204){
                return res;
            }
            else {
                return Promise.reject({
                    status: res.status,
                    statusText: res.statusText,
                    url,
                    requestOptions
                });
            }
        });
}

const TelematicsFileFetch = (url: string, requestOptions: any) => {
    return fetch(url, requestOptions)
        .then((res: Response): Promise<any> => {
            if (res.status == 200) {
                return res.blob();
            }
            else {
                return Promise.reject({
                    status: res.status,
                    statusText: res.statusText,
                    url,
                    requestOptions
                });
            }
        });
}