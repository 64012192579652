import React, { Component, ReactElement } from 'react';
import * as GdkFooter from '@gdk/footer';

export default class PrimaryFooterNavigation extends Component {

    componentDidMount(): void {
        GdkFooter.init();
    }

    render(): ReactElement {
        return (

            <footer id="primary-footer" className="footer">
                <div id="footer-links-container">
                    <div id="footer-links-secondary" className="footer" >
                        <ul>
                            <li><a href="https://www.geico.com/privacy/">Privacy</a></li>
                            <li><a href="#">Legal & Security</a></li>
                            <li><a href="#">Contact Us</a></li>
                        </ul>
                    </div>
                    <div id="footer-links-mobile">
                        <ul>
                            <li><a href="#">Privacy</a></li>
                            <li><a href="#">Legal & Security</a></li>
                            <li><a href="#">Contact Us</a></li>
                        </ul>
                    </div>
                </div>
                <div id="footer-copyright">GEICO © 1996-<span id="footerDate"></span></div>
            </footer>
        );
    }
}