import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import { ActionWithPayload, KnownAction } from './Actions';
import MapPopup from '../components/common/MapPopup';
import DanlawVehicle from '../models/DanlawVehicle';
import { setCustomerDashboardPanel } from '../helpers/navigationHelpers';

export const actionCreators = {
    updateSelectedVehicle: (vehicle: DanlawVehicle): AppThunkAction<ActionWithPayload> => (dispatch: any) => {
        dispatch({
            type: 'UPDATE_SELECTED_VEHICLE',
            payload: vehicle
        });
    },
    generateMapPopups: (vehicles?: DanlawVehicle[], vehicle?: DanlawVehicle, singleVehicleMap?: any, vehiclesMap?: any): AppThunkAction<ActionWithPayload> => (dispatch: any) => {
        let popups: any[] = [];
        let index = 1;
        if (vehicles){
            vehicles.forEach((vehicle: DanlawVehicle) => {
                let newPopup = {
                    id: "popup" + index,
                    chipStatus: vehicle.chipStatus,
                    chipMessage: vehicle.chipMessage,
                    vehicle: vehicle,
                    singleVehicleMap: singleVehicleMap,
                    vehiclesMap: vehiclesMap,
                    vehicles: vehicles,
                };
                popups.push(newPopup);
                index++;
            });
        }
        
        return {
            type: 'GENERATE_MAP_POPUPS',
            payload: popups
        };
    },
    vehicleInformationOnClick: (vehicle: DanlawVehicle, singleVehicleMap: any, vehicles: DanlawVehicle[]): AppThunkAction<ActionWithPayload> => (dispatch: any) => {
        setCustomerDashboardPanel("single-vehicle");

        vehicles.forEach((vehicleFromList: DanlawVehicle) => {
            let svgIcon = document.getElementById("svgIconSingle-" + vehicleFromList.id) as HTMLElement;
            if (vehicleFromList.id != vehicle.id) {
                svgIcon.setAttribute("style", "display: none");
            } else {
                svgIcon.setAttribute("style", "display: block");
            }
        });
    
        let markers = singleVehicleMap.getMarkers();
        markers.forEach(async (marker: any) => {
            if (marker._element.id === ("svgIconSingle-" + vehicle.id)) {
                /// 0 second timeout allows the map to display the marker before the flyTo function is called.
                await new Promise(resolve => setTimeout(resolve, 0));
                singleVehicleMap.flyTo({
                    center: [marker._lngLat.lng, marker._lngLat.lat],
                    zoom: 19,
                    speed: 1,
                    duration: 2500
                });
            }
        });
        
        dispatch(actionCreators.updateSelectedVehicle(vehicle));
    }
};

const unloadedState: any = {
    selectedVehicle: undefined,
    popups: undefined,
} as any;

export const reducer:
    Reducer<any> = (
        state: any | undefined,
        incomingAction: Action,
    ): any => {
        
        if (state === undefined) {
            return unloadedState;
        }

        const action = incomingAction as KnownAction;
        switch (action.type) {
            case "GENERATE_MAP_POPUPS":
                return {
                    ...state,
                    generateMapPopups: action.payload,
                    isLoading: false,
                }
            case "UPDATE_SELECTED_VEHICLE":
                return {
                    ...state,
                    selectedVehicle: action.payload,
                    isLoading: false,
                }
            default:
                return state;
        }
    };
