import { Account } from "msal"
import { StringDict } from "msal/lib-commonjs/MsalTypes"

export class LoggedInUser {
    isAuthenticated: boolean
    account: any
    userRole: UserRole
    idToken: any
    hasError: boolean
    errorMessage: any

    constructor(isDemo: boolean) {
        let strDict: StringDict;
        this.isAuthenticated = isDemo;
        this.account = isDemo ?
            {
                accountIdentifier: "",
                homeAccountIdentifier: "",
                userName: "DemoUser",
                name: "DemoUser",
                idToken: "",
                idTokenClaims: "",
                sid: "",
                environment: ""
            }
            : null;
        this.userRole = isDemo ? UserRole.Admin : UserRole.None;
        this.idToken = null;
        this.hasError = false;
        this.errorMessage = null;
    }

    static GetUserRole(account: any): UserRole {
        let roles = account?.idToken?.roles;
        if (roles) {
            if (roles.indexOf('Admin') >= 0) {
                return UserRole.Admin;
            }
            if (roles.indexOf('ServiceUser') >= 0) {
                return UserRole.ServiceUser;
            }
            if (roles.indexOf('Agent') >= 0) {
                return UserRole.Agent;
            }
        }
        return UserRole.None;
    }

    static GetRoleName(user: LoggedInUser): string {
        if (user) {
            switch (user.userRole) {
                case UserRole.Admin:
                    {
                        return "Admin"
                    }
                case UserRole.Agent:
                    {
                        return "Agent"
                    }
                case UserRole.ServiceUser:
                    {
                        return "Service User"
                    }
                default:
                    {
                        return "No Access"
                    }
            }
        }
        return "No Access";
    }

    GetRole(): UserRole {
        if (this.account && this.account.roles) {
            if (this.account.roles.indexOf('Admin') >= 0) {
                return UserRole.Admin;
            }
            if (this.account.roles.indexOf('ServiceUser') >= 0) {
                return UserRole.ServiceUser;
            }
            if (this.account.roles.indexOf('Agent') >= 0) {
                return UserRole.Admin;
            }
        }
        return UserRole.None;
    }
}

export enum UserRole {
    Admin,
    Agent,
    None,
    ServiceUser
}